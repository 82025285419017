.loader{
    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    background-color: rgba(235, 231, 231, 0.679);
    z-index: 1000;
}