.etablissementDemandeEnvoyePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  width: 100vw;
  .etablissementDemandeEnvoyePage__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 70%;

    padding: 24px 64px;
    gap: 64px;
    text-align: center;
    & > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #003e5c;
      margin: 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
      text-align: left;
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
    }
  }
  .etablissementDemandeEnvoyePage__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 83%;
    .etablissementDemandeEnvoyePage__main__topButtonSection {
      // width: 99%;
      button {
        font-family: Montserrat;
        font-size: 16px;
      }
    }
  }
  footer {
    width: 100vw;
    height: 100px;
    margin-top: auto;
    background-size: cover;
    object-fit: fill;
  }
  section{
    display: block;
  }
}
