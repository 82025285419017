.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .pageNotFound__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 32px;
    max-width: 845px;
    min-height: 239px;
    background: #ffffff;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    box-sizing: border-box;

    margin-top: auto;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #00aec3;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
    }
    a {
      // Revenir à la page d’accueil button
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      gap: 8px;

      width: max-content;
      height: 42px;
      background: #ffffff;

      border: 1px solid #003e5c;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      text-decoration: none;

      // Text style
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
    }
  }

  .pageNotFound__footer {
    width: 100vw;
    height: 100px;
    margin-top: auto;
    background-size: cover;
    object-fit: fill;
  }
}
