.vmvValeurs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 64px;
  gap: 64px;
  box-sizing: border-box;
  background: #ffffff;
  height: auto;
  height: max-content;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin: 0;
  }
  .vmvValeurs__valeurs {
    display: flex;
    flex-direction: row;
    width: 100%;

    .vmvValeurs__valeurs__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: max-content;
      box-sizing: border-box;
      gap: 12px;
      padding: 64px;

      figure {
        width: 80px;
        height: 80px;
        background-size: cover;
        object-fit: fill;
        margin: 0;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1025px) {
  .vmvValeurs__valeurs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    height: max-content;
    .vmvValeurs__valeurs__item {
    }
  }
}
