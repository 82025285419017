.possibilitesAccueils {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 32px;
  // gap: 32px;
  gap: 64px;
  padding: 64px;
  background: #f6f6f6;
  border-radius: 8px;
  margin-top: 24px;

  .possibilitesAccueils__header {
    h1 {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .possibilitesAccueils__main {
    gap: 32px;
  }
  .possibilitesAccueils__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
  }
}
