.metier {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  gap: 32px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 32px;
  flex-wrap: wrap;

  .metier__metier{
    display: block;
    text-align: left;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 8px;
    color: #00aec3;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #00aec3;
  }
  .container__tabs{
    // background-color: blue;
    flex-direction: row;
    justify-content: space-around;
  
    .bloc__tabs{
      flex-direction: column;
      box-sizing: border-box;
      // background-color: red;
      padding-top: 15px;

      .tabs{
      width: 200px; /* Defina a largura desejada */
      height: 70px; /* Defina a altura desejada */
      background-color: #ffffff; /* Cor de fundo */
      border: 1px solid #cccccc; /* Contorno cinza */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra gradiente suave */
      border-radius: 10px; /* Borda arredondada */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      margin-bottom: 7px;
      
        h3{
          
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          font-family: Montserrat;
          // line-height: 20px;
          color: #003e5c;
          box-sizing: border-box;
          margin: 0 0 0 0;
          letter-spacing: 1px;
        }
        p{
          font-style: normal;
          font-size: 10px;
          color: #003e5c;
          font-family: Montserrat;
          letter-spacing: 0.5px;
        
        }
      }
      .tab1{
        margin-top: 10px;
      }
      
    }
    .active__tab{
      width: 200px; /* Defina a largura desejada */
      height: 70px; /* Defina a altura desejada */
      background-color: #00AEC3; /* Cor de fundo */
      border: 1px solid #cccccc; /* Contorno cinza */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra gradiente suave */
      border-radius: 10px; /* Borda arredondada */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      margin-bottom: 7px;

        h3{
          
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          // line-height: 20px;
          color: white;
          box-sizing: border-box;
          margin: 0 0 0 0;
          font-family: Montserrat;
          letter-spacing: 1px;        
        }
        p{
          font-style: normal;
          // font-weight: 400;
          font-size: 10px;
          // line-height: 17px;
          color: white;
          font-family: Montserrat;
          letter-spacing: 0.5px;        
        }
    }
    
    .active__tab::before{
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 2px);
      height: 5px;
      background-color: green;
    }
  
  .metier__semaines {
    // background-color: green;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 0 0 10px;
    padding: 0 0 0 10px;
    
    // display: none;
    
    
    
    .metier__premiereSemaine,
    .metier__deuxiemeSemaine,
    .matier__troisiemeSemaine{
      margin-bottom: 32px;
    }

    .metier__premiereSemaine,
    .metier__deuxiemeSemaine,
    .metier__troisiemeSemaine,
    .metier__quatriemeSemaine{
      display: none;
      h3 {
        text-align: left;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
        margin: 0 0 30px 0;
        padding: 0 0 0 5px;
      }
      .metier__semaine__list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        box-sizing: border-box;
      }
      .metier__jourBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px;
        width: 188px;
        height: 192px;
        box-sizing: border-box;
        background: #f6f6f6;
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        .metier__jourBox__date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // width: 90%;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;

          label {
            color: #00aec3;
          }
        }
        .metier__jourBox__label {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
        .metier__jourBox__counter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          gap: 5px;
          // width: 132px;
          height: 36px;
          background: #ffffff;
          border-radius: 8px;

          input {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;

            border: none;
            text-align: center;

            font-family: Montserrat;
            font-size: 16px;
            color: #003e5c;
            // This removes the default up and down arrows from the input of type number
            -moz-appearance: textfield;
            -webkit-appearance: textfield;
            appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &::placeholder {
              color: #afafaf;
              font-family: Montserrat;
              font-size: 16px;
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            gap: 10px;
            width: 36px;
            height: 36px;
            background: #dfdfdf;
            border-radius: 0px 8px 8px 0px;
            border: none;
          }
          button:nth-child(1) {
            border-radius: 8px 0px 0px 8px;
          }
        }
      }
      .active__semaine{
        display: block;
      }

    }
  }
}
}


@media (max-width: 1025px) {
  .metier__semaines {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    box-sizing: border-box;
    .metier__premiereSemaine,
    .metier__deuxiemeSemaine, 
    .matier__troisiemeSemaine, 
    .metier__quatriemeSemaine {
      display: flex;
      flex-direction: column;
      align-items: center;
      .metier__semaine__list {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
