.possibilitesAccueilsEtabRecap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 32px;
  // padding: 34px;
  gap: 64px;
  padding: 64px;
  background: #f6f6f6;
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 24px;

  width: 100%;
  .possibilitesAccueilsEtabRecap__header {
    h1{
      margin:  0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .possibilitesAccueilsEtabRecap__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 64px;
  }
}
