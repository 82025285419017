.etablissementConfirmationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  box-sizing: border-box;
  .etablissementConfirmationPage__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 64px;
    box-sizing: border-box;
    font-family: Montserrat;
    width: 100%;
    gap: 24px;
    padding-top: 64px;
    & > h1 {
      margin: 0;
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      text-align: center;
      color: #003e5c;
    }
    & > h2 {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #003e5c;
    }
  }
  .etablissementConfirmationPage__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    box-sizing: border-box;
    gap: 24px;

    .etablissementConfirmationPage__main__buttonSection,
    .etablissementConfirmationPage__main__buttonSection__secondary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .etablissementConfirmationPage__main__buttonSection{
        margin-bottom: 24px;
    }
    .etablissementConfirmationPage__main__buttonSection__secondary{
        margin-top: 24px;
    }
    section{
      display: block;
    }
  }
}
footer {
  width: 100vw;
  height: 100px;
  margin-top: auto;
  background-size: cover;
  object-fit: fill;
}
