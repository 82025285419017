$submitButton_color1_gradient: #003e5c;
$submitButton_color2_gradient: #00aec3;

.etablissementRecapPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  box-sizing: border-box;
  .etablissementRecapPage__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 64px;
    gap: 64px;
    box-sizing: border-box;
    h1,
    p {
      margin: 0;
    }
  }
  .etablissementRecapPage__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    box-sizing: border-box;
    gap: 24px;

    & > button[type="submit"] {
      align-self: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      // margin-top: 42px;
      margin-bottom: 10px;
      padding: 16px 32px;

      background: linear-gradient(
        246.3deg,
        $submitButton_color1_gradient 14.46%,
        $submitButton_color2_gradient 84.5%
      );
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 40px;
      border: none;

      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      color: #ffffff;
      text-decoration: none;
      font-family: Montserrat;
      padding: 16px 32px;
      gap: 8px;

      width: 329px;
      height: 54px;
      box-sizing: border-box;
      &:hover {
        background: linear-gradient(
          246.3deg,
          lighten($submitButton_color1_gradient, 10%) 14.46%,
          lighten($submitButton_color2_gradient, 10%) 84.5%
        );
      }
    }
  }

  footer {
    width: 100vw;
    height: 100px;
    margin-top: auto;
    background-size: cover;
    object-fit: fill;
  }
}
