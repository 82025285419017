.vmvDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  // height: 304px;
  padding: 64px 183px;
  box-sizing: border-box;
  background: rgba(178, 229, 237, 0.2);

  strong {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    align-self: flex-start;

  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    align-self: flex-start;
  }
}
