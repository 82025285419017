.inscriptionAnnuleeNotification {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  //   justify-content: space-between;
  //   align-items: flex-start;
  padding: 32px;
  margin-top: 64px;
  margin-bottom: 64px;
  gap: 40px;
  // width: 1554px;
  width: 85%;
  height: 210px;
  box-sizing: border-box;
  background: linear-gradient(
      0deg,
      rgba(225, 59, 23, 0.05),
      rgba(225, 59, 23, 0.05)
    ),
    #ffffff;

  border: 3px solid #e13b17;
  border-radius: 26px;

  .inscriptionAnnuleeNotification__header {
    display: flex;
    align-items: center;
    width: 100%;
    h1 {
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;

      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;

      color: #e13b17;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
    }
  }
  .inscriptionAnnuleeNotification__main {
    align-self: flex-start;
    p {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      color: #003e5c;
    }
  }
}
