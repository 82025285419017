.button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // width: 127px;
  height: 42px;
  width: max-content;
  padding: 0px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #003e5c;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  
  // Text styling
  // font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #003e5c;
  font-family:  Montserrat;
}

// background types
.button[data-background-color="white"] {
  background-color: #fff;
  border: 1px solid #003e5c;
}
.button[data-background-color="blue"] {
  background-color: #00aec3;
  border: 1px solid #00aec3;
}
.button[data-background-color="red"] {
  background-color: #e13b17;
  border: 1px solid #e13b17;
}
.button[data-background-color="linear-blue"] {
  background: linear-gradient(246.3deg, #003e5c 14.46%, #00aec3 84.5%);
  border: transparent;
}

// text color types
.button[data-text-color="white"] {
  color: #fff;
}

.button[data-text-color="dark"] {
  color: #003e5c;
}
.button[data-text-color="blue"] {
  color: #00aec3;
}

// optional border styles
.button[data-border-color="dark"] {
  border: 1px solid #003e5c ;
}
.button[data-border-color="blue"] {
  border: 1px solid #00aec3;
}
.button[data-border-color="white"] {
  border: 1px solid #fff;
}
.button[data-border-color="red"] {
  border: 1px solid #e13b17;
}
.button[data-border-color="transparent"] {
  border: transparent;
}
