.dropdownSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // padding: 16px;
  // min-width: 45%;
  margin-bottom: 32px;
  text-align: left;
 // box-sizing: border-box;
  .dropdownSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    gap: 16px;
    width: 333px;
    height: 42px;
    margin-top: 8px;
    background: #ffffff;

    //box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: 1px solid #003E5C;

    // Option text styles
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #003e5c;
  }
  select[has-error="yes"] {
    border: 1px solid #e13b17;
  }

  .dropdownSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
