.inscriptionEtablissement__main__infoDE {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  //   height: 297px;

  margin: 64px 0 64px 0;
  background: #f6f6f6;
  border-radius: 8px;
  

  .inscriptionEtablissement__main__infoDE__inputs {
    
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    & > section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    label{
      
      text-indent: 8px;
    }
  }
}

@media (max-width: 1025px) {
  .inscriptionEtablissement__main__infoDE {
    .inscriptionEtablissement__main__infoDE__inputs{
width: 100%;
    }
  }
}
