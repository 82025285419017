.informationSection {
  min-width: 289px;

  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #003e5c;
  }
  section {

    max-width: 289px;
    
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #003e5c;
       word-wrap: break-word;
  overflow-wrap: break-word;
    }
  }
}
