.inscriptionEtablissement__main__infoEtablissement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  box-sizing: border-box;
  max-width: 1461px;

  background-color: blue($color: #000000);
  background: #f6f6f6;
  border-radius: 8px;
  height: max-content;
  .inscriptionEtablissement__main__infoEtablissement__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

    width: 100%;

    height: auto;
    .inscriptionEtablissement__main__infoEtablissement__container__left {
      display: flex;
      flex-direction: column;
      width: 47%;
      min-height: 344px;
      height: auto;
      flex-wrap: wrap;
      text-align: left;
      padding: 0 0 0 0 ;
      & > section:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        width: 100%;
        padding: 0 0 0 0;
        // background-color: red;
      }
      & > section:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: left;
        width: 100%;
      }
      & > section:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: left;
        width: 100%;
        label{
         margin-bottom: 8px;
        }
        // background-color: red;
        
      }
      label{
        text-indent: 8px;
      }
    }
    .inscriptionEtablissement__main__infoEtablissement__container__separationLine {
      width: 1px;
      height: 344px;
      background: #afafaf;
    }
    .inscriptionEtablissement__main__infoEtablissement__container__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // background-color: #000000;
      width: 47%;
      min-height: 172px;
      height: auto;
      text-align: left;
      & > section:nth-child(1) {
        display: flex;
        align-items: left;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 0 0 0 ;
        
        
      }
      & > section:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
      }
      label{
        text-indent: 8px;
      }
    }
  }
}

/* Medium screens */
@media (max-width: 1025px) {
  .inscriptionEtablissement__main__infoEtablissement {
    width: 100%;
    .inscriptionEtablissement__main__infoEtablissement__container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .inscriptionEtablissement__main__infoEtablissement__container__left,
      .inscriptionEtablissement__main__infoEtablissement__container__right {
        display: flex;
        justify-content: space-between;
        text-align: left;

        width: 100%;
      }

      .inscriptionEtablissement__main__infoEtablissement__container__separationLine {
        display: none;
      }
    }
  }
}
