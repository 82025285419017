body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden; /* removes horizontal scrollbar */
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

h1,
h2,
p,
strong,
label {
  font-family: "Montserrat";
  color: #003e5c;
}
input::placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #809eae;
}
textarea::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #809eae;
}
input {
  outline: none;
}

section{
  display: flex;
  
}


.mantine-Accordion-control {
  /* The accordion questions container */
  padding: 20px;
  background: #dfdfdf;
  border-radius: 10px;
}
.mantine-Accordion-control:hover {
  background: #dfdfdf;
}
.mantine-Accordion-label {
  /* The accordions questions label (Actual text inside the accordion item header)  */
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #003e5c;
}
.mantine-Accordion-content {
  /* The accordion answers */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #003e5c;
}

/* Removes the background color of mantine modal */
/* I mainly use the mantine modal for popups */
.mantine-Modal-modal {
  background-color: transparent;
}
