.informationEtabModification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px;
  gap: 64px;
  background: #ffffff;
  border: 1px solid #003e5c;
  border-radius: 20px;
  box-sizing: border-box;
  width: 90vw;
  
  .informationEtabModification__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > button {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      gap: 8px;
      width: 127px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #003e5c;
      //box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      // Text styling
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
    }
  }

  .informationEtabModification__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 32px;
    .informationEtabModification__top {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      height: 100%;
      flex-wrap: wrap;

      .informationEtabModification__top__left,
      .informationEtabModification__top__right {
        display: flex;
        flex-direction: column;
        gap: 32px;

        min-height: 344px;
        // width: 100%;
        width: 45%;

        & > section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
        }
      }

      .informationEtabModification__top__separationLine {
        width: 1px;
        height: 344px;
        background: #afafaf;
        display: none;
      }
    }

    .informationEtabModification__bottom {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      text-align: left;
      label{
      
        text-indent: 8px;
      }

      .informationEtabModification__bottom__DE,
      .informationEtabModification__bottom__REF {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        // min-width:49%;
        width:600px;
        text-align: left;
        & > main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;

        }
      }
    }
  }

  .informationEtabModification__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
  }
}

@media (max-width: 1025px) {
  .informationEtabModification {
    .informationEtabModification__main {
      text-align: left;

      .informationEtabModification__top {
      }
    }
  }
}
