$buttonBackgroundColor: #003e5c;

.vmvPlaceholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 432px;

  .vmvPlaceholder__left,
  .vmvPlaceholder__right {
    width: 50%;
    height: 100%;
  }
  .vmvPlaceholder__left {
    background-size: cover;
    object-fit: contain;
    
  }
  .vmvPlaceholder__right {
    display: flex;
    flex-direction: column;
    background: linear-gradient(232.74deg, #91be1e -3.26%, #00aec3 94.84%);
    gap: 32px;
    padding: 64px;
    box-sizing: border-box;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;

      padding: 0;
      margin: 0;
      color: #003e5c;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #003e5c;
      margin: 0;
    }
    p {
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: justify;

      color: #003e5c;
      margin: 0;
    }
    a {
      // Button style
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;

      width: 175px;
      height: 54px;
      box-sizing: border-box;
      background: $buttonBackgroundColor;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 40px;
      border: none;

      margin: 0;
      //   Text style
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;

      color: #ffffff;
      text-decoration: none;
      &:hover {
        background-color: lighten($buttonBackgroundColor, 10%);
      }
    }
  }
}
