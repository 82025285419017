$submitButton_color1_gradient: #003e5c;
$submitButton_color2_gradient: #00aec3;
.inscriptionEtablissement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: space-around;
  width: 100vw;
  padding: 24px 64px;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  
  .inscriptionEtablissement__header {
    margin: 0 0 0 0px;
    width: 83%;
    & > h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin: 0;
      padding: 64px;
    }
    .inscriptionEtablissement__header__info {
      // width: 50vw;
      display: block;
      text-align: left;
      margin: 0 0 0 0 ;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        width: 50vw;
        align-self: stretch;
      }
    }
    .inscriptionEtablissement__header__obg{
      
      text-align: left;
      margin: 0 0 0 0;
      p{
        margin-bottom: 64px;
        
      }
    }
  }

  .inscriptionEtablissement__main {
    form {
      display: flex;
      flex-direction: column;
      gap: 24;
      button[type="submit"] {
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 61px;

        margin-top: 42px;
        margin-bottom: 24px;
        padding: 16px 32px;
        gap: 8px;

        background: linear-gradient(
          246.3deg,
          $submitButton_color1_gradient 14.46%,
          $submitButton_color2_gradient 84.5%
        );
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
        border-radius: 40px;
        border: none;

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        text-decoration: none;
        &:hover {
          background: linear-gradient(
            246.3deg,
            lighten($submitButton_color1_gradient, 10%) 14.46%,
            lighten($submitButton_color2_gradient, 10%) 84.5%
          );
        }
      }
    }
  }

  footer {
    width: 100vw;
    height: 100px;
    margin-top: auto;
    background-size: cover;
    object-fit: fill;
  }
}
