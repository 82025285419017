.changeEtablissementTypePopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 32px;
  width: 700px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 26px;
  .changeEtablissementTypePopup__header {
    display: flex;
    align-items: center;
    width: 100%;

    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #00aec3;
      line-height: 29px;

      margin-left: auto;
      margin-bottom: 0;
      margin-top: 0;
    }
    button {
      background: none;
      border: none;
      margin-left: auto;
    }
  }

  .changeEtablissementTypePopup__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;

    .changeEtablissementTypePopup__main__textBloc {
      p {
        margin: 0;
      }
    }
    .changeEtablissementTypePopup__main__screen {
      width: 564.34px;
      height: 340px;
      margin-top: auto;
      background-size: cover;
      object-fit: fill;
      border: 1.3px solid black;
      border-radius: 20px;
    }
  }

  .changeEtablissementTypePopup__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    margin-top: 52px;
  }
}
