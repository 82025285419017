.actionPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 32px;
  gap: 40px;
  width: 700px;

  background: #ffffff;
  border-radius: 26px;
  .actionPopup__header {
    display: flex;
    align-items: center;
    width: 100%;
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-left: auto;
      margin-bottom: 0;
      margin-top: 0;
    }
    button {
      background: none;
      border: none;
      margin-left: auto;
    }
  }
  .actionPopup__main {
    width: 100%;
    display: flex;

    flex-direction: column;
    p {
      font-style: normal;
      min-height: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
      margin: 0;
    }
  }
  .actionPopup__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    height: 60px;
    button {
      font-family: Montserrat;
      font-size: 16px;
    }
  }
}
