.informationEtab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px;
  gap: 64px;
  background: #f6f6f6;
  border-radius: 20px;
  box-sizing: border-box;

  & > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > button {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      gap: 8px;
      width: 127px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #003e5c;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      // Text styling
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #003e5c;
    }
  }

  & > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 32px;

    .informationEtab__top {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      height: 100%;
      flex-wrap: wrap;
      .informationEtab__top__left,
      .informationEtab__top__right {
        display: flex;
        flex-direction: column;
        gap: 32px;
        min-height: 344px;
        width: 48%;
        & > section {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          & > article {
          }
          width: 100%;
        }
      }

      .informationEtab__top__separationLine {
        width: 1px;
        height: 344px;
        background: #afafaf;
        display: none;
      }
    }

    .informationEtab__bottom {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;

      .informationEtab__bottom__DE {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 600px;

        & > main {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          & > section {
            display: flex;
            justify-content: space-between;
            article {

            }
          }
        }
      }
      .informationEtab__bottom__REF {
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 600px;

        & > main {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          & > section {
            display: flex;
            width: 100%;
            article {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
