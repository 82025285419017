.login {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: #809eae;
  .login__loginWindow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 32px;
    gap: 64px;

    margin: 15px;
    max-width: 600px;
    min-height: 551px;

    min-width: 500px;

    background: #ffffff;

    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    box-sizing: border-box;

    .login__loginWindow__header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #00aec3;
      }
    }

    .login__loginWindow__main {
      display: flex;
      flex-direction: column;
      gap: 32px;

      // align-items: center;
      // justify-content: space-between;
    }
    .login__loginWindow__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button[type="submit"] {
        padding: 16px 32px;
        gap: 8px;
        width: 230px;
        height: 61px;
        background: linear-gradient(246.3deg, #003e5c 14.46%, #00aec3 84.5%);
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2);
        border-radius: 40px;
        border: none;
        // Text style

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;

        &:hover {
          background: (
            linear-gradient(
              246.3deg,
              lighten(#003e5c, 10%) 14.46%,
              lighten(#00aec3, 10%) 84.5%
            )
          );
        }
      }
    }
  }
}
