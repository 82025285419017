.mapsSearchSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // min-width: 45%;
  margin-bottom: 32px;
  box-sizing: border-box;
  .mapsSearchSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .mapsSearchSection__search {
    position: relative;
    min-width: 240px;
    .mapsSearchSection__search__input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 10px;
      min-width: 289px;
      height: 44px;
      margin-top: 8px;
      background: #ffffff;
      // box-sizing: border-box;
     // box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      border: 1px solid transparent;

      color: #003e5c;
      font-family: Montserrat;
      font-size: 14px;
    }
    .mapsSearchSection__search__input[has-error="yes"] {
      border: 1px solid #e13b17;
    }

    .mapsSearchSection__search__options {
      position: absolute;
      top: calc(
        100% + 4px
      ); /* adjust this value as needed to position the dropdown */
      left: 0;
      min-width: 289px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: none;
      // height: 250px;
      overflow-y: scroll;
      // gap: 16px;
      box-sizing: border-box;
      // // height: 556px;

      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      z-index: 100;
      option {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
        margin-bottom: 16px;
        white-space: normal;
        word-wrap: break-word;
        cursor: pointer;

        &:hover {
          background-color: darken(#ffffff, 10%);
        }
      }
    }
  }

  .mapsSearchSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
