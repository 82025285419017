.textAreaSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 112px;
  height: auto;
  // height: 200px;
  width: 100%;
  .textAreaSection__titleLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  textarea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    min-height: 112px;

    background: #ffffff;
    resize: none;
    overflow: scroll;
   // box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;

    color: #003e5c;
    font-family: Montserrat;
    font-size: 14px;

    //overflow-y: hidden; /* removes vertical scrollbar */
    overflow-x: hidden; /* removes horizontal scrollbar */
  }
  textarea[has-error="yes"] {
    border: 1px solid #e13b17;
  }

  .textAreaSection__errorLabel {
    height: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #e13b17;
  }
}
