.metierRecap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  gap: 32px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 20px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 8px;
    color: #00aec3;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #00aec3;
  }

  .metier__semaines {
    // background-color: #003e5c;
    justify-content: space-between;
    display: block;
    .metier__premiereSemaine {
      margin-bottom: 32px;
      
    }
    .metier__premiereSemaine,
    .metier__deuxiemeSemaine, .metier__troisiemeSemaine {
      margin-bottom: 32px;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #003e5c;
      }
      .metier__semaine__list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
      }
      .metier__jourBox {
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        width: 192px;
        height: 188px;
        // height: max-content;
        box-sizing: border-box;
        background: #f6f6f6;
        border-radius: 20px;

        gap: 12px;
        
        .metier__jourBox__date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // width: 90%;
          label {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: Montserrat;
            color: #00aec3;
          }
        }

        .metier__jourBox__info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 16px 0px;
          gap: 10px;
          width: 156px;
          height: 124px;
          background: #ffffff;
          border-radius: 12px;
          label {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .metier__jourBox__indisponible {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .metier__semaines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    box-sizing: border-box;
    .metier__premiereSemaine,
    .metier__deuxiemeSemaine {
      display: flex;
      flex-direction: column;
      align-items: center;
      .metier__semaine__list {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
